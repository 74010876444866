import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/nedix/projects/internal/nedix.io/git_modules/gatsby-theme-carbon/packages/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Wayback Machine`}</h2>
    <p><strong parentName="p">{`Archive a page`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`javascript:void(window.open('https://web.archive.org/save/'+location.href,%20'_self'));
`}</code></pre>
    <p><strong parentName="p">{`Retrieve first record of a page`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`javascript:void(window.open('https://web.archive.org/web/1if_/'+location.href,%20'_self'));
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      